<template>
  <div class="clearBox">
    <div class="content-box" style="padding-top: 24px">
        <!-- 标题 -->
        <!-- 左侧非地图部分 -->
        <div class="content-main-left">
          <!-- 标段输入项 -->
          <div class="content-main-hw" style="padding-top: 0px">
            <el-form :model="form" :rules="rules" ref="form" :inline="true" label-position="right" label-width="170px">
                <div>
                  <span class="title">基本信息</span>
                </div>
              <el-form-item label="标段类型:" style="margin-bottom: 0px">
                <span>{{ form.cargoArrivalNumber }}</span>
              </el-form-item>
              <el-form-item label="服务范围:">
                <span>{{ form.cargoArrivalNumber }}</span>
              </el-form-item>
              <el-form-item label="标段编号:">
                <span>{{ form.cargoArrivalNumber }}</span>
              </el-form-item>
              <el-form-item label="养护企业:">
                <span>{{ form.cargoArrivalNumber }}</span>
              </el-form-item>
              <el-form-item label="项目经理:">
                <span>{{ form.cargoArrivalNumber }}</span>
              </el-form-item>
              <el-form-item label="联系方式:">
                <span>{{ form.cargoArrivalNumber }}</span>
              </el-form-item>
              <el-form-item label="应配人员数">
                <el-input v-model="form.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="已配人员数">
                <el-input v-model="form.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="项目人员变更情况:">
                <span>{{ form.cargoArrivalNumber }}</span>
              </el-form-item>
              <el-form-item label="车辆变更情况:">
                <span>{{ form.cargoArrivalNumber }}</span>
              </el-form-item>
              <el-form-item label="应配车辆数">
                <el-input v-model="form.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="已配车辆数">
                <el-input v-model="form.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="应配现场管理人数:">
                <span>{{ form.cargoArrivalNumber }}</span>
              </el-form-item>
              <el-form-item label="应配现场作业人数:">
                <span>{{ form.cargoArrivalNumber }}</span>
              </el-form-item>
              <el-form-item label="应配安全生产管理人数">
                <el-input v-model="form.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="应配组长人数">
                <el-input v-model="form.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="应配机扫车数量" >
                <el-input v-model="form.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="应配洒水车数量" >
                <el-input v-model="form.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="应配冲洗车数量" >
                <el-input v-model="form.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="应配路面养护车车数量" >
                <el-input v-model="form.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="应配平板车数量" >
                <el-input v-model="form.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="应配勾臂车数量" >
                <el-input v-model="form.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="应配桶装垃圾运输车数量" >
                <el-input v-model="form.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="应配大件垃圾运输车数量" >
                <el-input v-model="form.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="应配转运站操作员数量" >
                <el-input v-model="form.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="应配公厕保洁员数量" >
                <el-input v-model="form.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="应配水电工数量" >
                <el-input v-model="form.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <div class="font-title">
                审核结果
              </div>
              <el-form-item label="审核结果:" style="width: 100%">
                <el-radio v-model="form.radio" label="通过">通过</el-radio>
                <el-radio v-model="form.radio" label="不通过">不通过</el-radio>
              </el-form-item>
              <el-form-item label="审核意见" style="width: 100%">
                <el-input v-model="form.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="审核人员" style="width: 100%">
                <el-input v-model="form.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
            </el-form>
            
          </div>
        </div>
        <!-- 地图部分 -->
        <ayl-map v-model="mapData"></ayl-map>
      </div>

      <div class="tac mt40px">
        <el-button type="success" class="btn-success-hw" @click="create_order(0)">提交</el-button>
      </div>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        map: null,
        theMouseTool: null,
        radio: 1,
        partnerList: [],
        form: {
          cargoArrivalNumber: '测试测试',
          radio: null,
        },
        rules: {
          cargoArrivalNumber: [{required: true, message: '请输入到货车号', trigger: 'blur'},{validator: this.$validator.isContainBlank}],
        },
        mapData: {
          //地图数据
          lnglat: null, //经纬度，数组，形如[118,36]
          scope: null //标段范围，经纬度点阵数组，形如[[118,36],[117,32],[116,113]]
        },
      }
    },
    methods: {

    },
    async mounted () {

    }
  }
</script>

<style lang='sass' scoped>
.clearBox
  .content-box
    .content-main
      display: inline-block
      width: 100%
      
      &-left
          float: left
          width: 808px
          .font-title
             font-size: 16px
             margin: 20px 0
      &-right
          float: left
          height: 777px
          width: calc(100% - 808px)
          background: #EDEDED
  .order
    overflow-x: visible !important
    &-type
      display: inline-block
      padding-right: 14px
      font-size: 14px
      font-family: Microsoft YaHei
      font-weight: 400
      color: rgba(102,102,102,1)
  .title
    width: 64px
    height: 17px
    font-size: 16px
    font-family: Microsoft YaHei
    font-weight: 400
    padding-left: 10px
    border-left: 2px solid #1ABC9C
    line-height: 18px
  
  .el-checkbox-group
    display: inline-block
  .tips
    width: 407px
    height: 22px
    margin-left: 170px
    font-size: 12px
    font-family: Microsoft YaHei
    font-weight: 400
    color: rgba(255,140,26,1)
    line-height: 32px
  .map-popover
    position: absolute
    z-index: 2
    top: 12px
    right: 12px
    width: 180px
    height: 32px
    background: rgba(255,255,255,1)
    box-shadow: 0px 2px 8px 0px rgba(31,46,41,0.3)
    border-radius: 2px

  /deep/ .el-radio__input.is-checked .el-radio__inner
    background: $-color-primary-3
    border-color: $-color-primary-3
  /deep/ .el-radio__input.is-checked + .el-radio__label
    color: $-color-primary-3
  /deep/ .el-checkbox__input.is-checked .el-checkbox__inner
    background: $-color-primary-3
    border-color: $-color-primary-3
  /deep/ .el-checkbox__input.is-checked + .el-checkbox__label
    color: $-color-primary-3
  /deep/ .el-form-item
    width: 48%

</style>
